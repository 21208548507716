import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import LayoutEmpty from "../components/LayoutEmpty";
import Fade from "react-reveal/Fade";

const CONTAINER = {
  backgroundColor: '#060405',
  display: 'flex',
  flex: 1,
  width: '100vw',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
}

export default function IndexPage() {
  return (
    <LayoutEmpty>
      <div style={CONTAINER}>
        <Fade>
        <img src="/img/landing-min-text.jpg" alt="Wimmic: A new direction for beam forming." />
        </Fade>
      </div>
  </LayoutEmpty>
  )
}